






















































































import Vue from "vue";
import { ApplicationResponse } from "@/api/application/types/Responses";

export default Vue.extend({
  name: "ApplicationDetail",
  props: {
    application: {
      type: ApplicationResponse,
      required: true,
    },
  },
  methods: {
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
