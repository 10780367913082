















































































import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { CreateApplicationChildrenRequest } from "@/api/application/types/Requests";
import { TranslateResult } from "vue-i18n";
import moment from "moment";

export default Vue.extend({
  name: "NewApplicationChildForm",
  mixins: [validationMixin],
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<any | undefined>,
      required: false,
    },
  },
  data: () => ({
    // dialogs
    birthdateDialog: false,
    // form data
    form: {
      first_name: "",
      last_name: "",
      birthdate: "",
      class: null,
    },
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      birthdate: { required },
      class: {},
    },
  },
  watch: {
    form: {
      deep: true,
      handler(): void {
        const request: CreateApplicationChildrenRequest = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          birthdate: this.form.birthdate,
          class: this.form.class,
        };
        this.$emit("onSubmit", request);
      },
    },
  },
  computed: {
    birthdateFormatted(): string {
      return this.form.birthdate ? moment(this.form.birthdate).format("LL") : "";
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("applications.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("applications.validation.last_name.required"));
      return errors;
    },
    birthdateErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.birthdate?.$dirty) return errors;
      !this.$v.form.birthdate.required && errors.push(this.$t("applications.validation.birthdate.required"));
      return errors;
    },
    classErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    touch(): void {
      this.$v.form.$touch();
    },
  },
  created() {
    if (this.defaults) {
      this.form = this.defaults;
    }
  },
});
