





































































































































































































import { ChildResponse } from "@/api/child/types/Responses";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { UserDetailResponse } from "@/api/user/types/Responses";
import Vue, { PropType } from "vue";
import { SchoolResponse } from "@/api/school/types/Responses";
import { AllItems } from "@/api/AbstractClient";
import SchoolClient from "@/api/school/SchoolClient";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import NewApplicationAuthorizedPersonForm from "@/components/applications/form/NewApplicationAuthorizedPersonForm.vue";
import {
  CreateApplicationAuthorizedPersonsRequest,
  CreateApplicationChildrenRequest,
  CreateApplicationRequest,
} from "@/api/application/types/Requests";
import NewApplicationChildForm from "@/components/applications/form/NewApplicationChildForm.vue";
import ApplicationClient from "@/api/application/ApplicationClient";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import _ from "lodash";

export default Vue.extend({
  name: "NewApplicationForm",
  components: { HelpMessage, NewApplicationChildForm, NewApplicationAuthorizedPersonForm },
  mixins: [validationMixin],
  props: {
    family: {
      type: Object as PropType<FamilyResponse>,
      required: false,
    },
    showHelpMessage: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // loading
    isBusySchools: false,
    isBusySchoolYears: false,
    isBusySubmit: false,
    // stepper
    step: 1 as number, // 1st step
    // enum
    schools: [] as SchoolResponse[],
    schoolYears: [] as SchoolYearResponse[],
    // form data
    form: {
      school: null as number | null,
      school_year: null as number | null,
      authorized_persons: {} as any,
      children: {} as any,
    },
  }),
  validations: {
    form: {
      school: { required },
      school_year: { required },
      authorized_persons: {
        required,
        $each: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
          phone: { required },
        },
      },
      children: {
        required,
        $each: {
          first_name: { required },
          last_name: { required },
          birthdate: { required },
          class: {},
        },
      },
    },
  },
  watch: {
    "form.school": function(schoolId?: number): void {
      if (schoolId) {
        this.fetchSchoolYears(schoolId);
      } else {
        this.schoolYears = [];
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySchools || this.isBusySchoolYears || this.isBusySubmit;
    },
    schoolsErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school?.$dirty) return errors;
      !this.$v.form.school.required && errors.push(this.$t("applications.validation.school.required"));
      return errors;
    },
    schoolYearsErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school_year?.$dirty) return errors;
      !this.$v.form.school_year.required && errors.push(this.$t("applications.validation.school_year.required"));
      return errors;
    },
  },
  methods: {
    onGoToAuthorizedPersons(): void {
      this.$v.form.school?.$touch();
      this.$v.form.school_year?.$touch();
      if (this.$v.form.school?.$anyError || this.$v.form.school_year?.$anyError) {
        return;
      }
      this.step = 2;
    },
    onGoToChildren(): void {
      for (let i = 0; i < Object.keys(this.form.authorized_persons).length; i++) {
        ((this.$refs["new-application-authorized-person-forms"] as any)[i] as any).touch();
      }
      this.$v.form.authorized_persons?.$touch();
      if (this.$v.form.authorized_persons?.$anyError) {
        return;
      }
      this.step = 3;
    },
    onSubmit(): void {
      for (let i = 0; i < Object.keys(this.form.children).length; i++) {
        ((this.$refs["new-application-child-forms"] as any)[i] as any).touch();
      }
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: CreateApplicationRequest = {
        school_year: _.toNumber(this.form.school_year),
        family: this.family?.id,
        application_authorized_persons: Object.values(this.form.authorized_persons),
        application_children: Object.values(this.form.children),
      };

      this.isBusySubmit = true;
      ApplicationClient.createApplication(request)
        .then((response) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onBack(): void {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    onSubmitAuthorizedPerson(request: CreateApplicationAuthorizedPersonsRequest, hash: string): void {
      this.form.authorized_persons[hash] = request;
    },
    onSubmitChild(request: CreateApplicationChildrenRequest, hash: string): void {
      this.form.children[hash] = request;
    },
    onAddAuthorizedPerson(user?: UserDetailResponse): void {
      const hash = this.generateHash(Object.keys(this.form.authorized_persons).length.toString());
      this.$set(this.form.authorized_persons, hash, {
        first_name: user?.first_name ?? "",
        last_name: user?.last_name ?? "",
        email: user?.email ?? "",
        phone: user?.phone ?? "",
      });
    },
    onAddChild(child?: ChildResponse): void {
      const hash = this.generateHash(Object.keys(this.form.children).length.toString());
      this.$set(this.form.children, hash, {
        first_name: child?.first_name ?? "",
        last_name: child?.last_name ?? "",
        birthdate: child?.birthdate?.format("YYYY-MM-DD") ?? "",
        class: "",
      });
    },
    onRemoveAuthorizedPerson(hash: string): void {
      if (hash in this.form.authorized_persons) {
        this.$delete(this.form.authorized_persons, hash);
      }
    },
    onRemoveChild(hash: string): void {
      if (hash in this.form.children) {
        this.$delete(this.form.children, hash);
      }
    },
    generateHash(prefix: string): string {
      const hash = prefix + "-" + _.random(1000, 9999).toString();
      if (hash in this.form.authorized_persons || hash in this.form.children) {
        return this.generateHash(prefix);
      }
      return hash;
    },
    fetchSchools(): void {
      this.isBusySchools = true;
      SchoolClient.getAllSchools(new AllItems())
        .then((response) => {
          this.schools = response.items;
        })
        .finally(() => {
          this.isBusySchools = false;
        });
    },
    fetchSchoolYears(schoolId: number): void {
      this.isBusySchoolYears = true;
      SchoolYearClient.getSchoolYears(schoolId, new AllItems())
        .then((response) => {
          this.schoolYears = response.items;
        })
        .finally(() => {
          this.isBusySchoolYears = false;
        });
    },
  },
  created(): void {
    this.fetchSchools();
    if (this.family) {
      this.family.authorized_persons.forEach((user: UserDetailResponse): void => this.onAddAuthorizedPerson(user));
      this.family.children.forEach((child: ChildResponse): void => this.onAddChild(child));
    } else {
      this.onAddAuthorizedPerson();
      this.onAddChild();
    }
  },
});
