


























































import Vue, { PropType } from "vue";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { TranslateResult } from "vue-i18n";
import { CreateApplicationAuthorizedPersonsRequest } from "@/api/application/types/Requests";

export default Vue.extend({
  name: "NewApplicationAuthorizedPersonForm",
  mixins: [validationMixin],
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<any | undefined>,
      required: false,
    },
  },
  data: () => ({
    form: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
    },
  },
  watch: {
    form: {
      deep: true,
      handler(): void {
        const request: CreateApplicationAuthorizedPersonsRequest = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          phone: this.form.phone,
        };
        this.$emit("onSubmit", request);
      },
    },
  },
  computed: {
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("applications.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("applications.validation.last_name.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("applications.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("applications.validation.email.email"));
      return errors;
    },
    phoneErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.phone?.$dirty) return errors;
      !this.$v.form.phone.required && errors.push(this.$t("applications.validation.phone.required"));
      return errors;
    },
  },
  methods: {
    touch(): void {
      this.$v.form.$touch();
    },
  },
  created() {
    if (this.defaults) {
      this.form = this.defaults;
    }
  },
});
